import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";

const ContactLenses = ({ data: { strapiMkoPrivacy } }) => {
  return (
    <PageLayout {...strapiMkoPrivacy} flourish={1}>
      <CTA />
    </PageLayout>
  );
};

export default ContactLenses;

export const query = graphql`
  query privacyPageQuery {
    strapiMkoPrivacy {
      content
      title
    }
  }
`;
